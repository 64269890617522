import axios, { AxiosProgressEvent, AxiosRequestConfig } from 'axios';

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: false,
});

export async function apiUpload<T = unknown>(
  orgId: string,
  url: string,
  data: unknown,
  isPresignedUrl?: boolean,
  config?: AxiosRequestConfig,
  progressCallback?: (progress: number) => void,
  userId?: string
) {
  const localParams = userId ? { user_id: userId } : {};
  const onUploadProgress = ({ loaded, total }: AxiosProgressEvent) => {
    const uploadPercentage = parseInt(String(Math.round((loaded / Number(total)) * 100)), 10);
    progressCallback?.(uploadPercentage);
  };

  if (isPresignedUrl) {
    return await api.post<T>(url, data, {
      onUploadProgress,
    });
  }

  return await axios.post<T>(url, data, {
    ...config,
    headers: {
      ...config?.headers,
      'Content-Type': 'multipart/form-data',
      'x-organization-id': orgId,
    },
    params: {
      ...config?.params,
      ...localParams,
    },
    onUploadProgress,
  });
}
