import { Badge, BadgeProps } from '@chakra-ui/react';
import { ConnectionStatus } from 'types/shared-types';

type Props = {
  status: string;
  style?: BadgeProps;
};

export default function ConnectionStateBadge({ status, style = {} }: Props) {
  let badge = null;

  if (status === ConnectionStatus.ACTIVE) {
    badge = (
      <Badge colorPalette={'green'} {...style}>
        Active
      </Badge>
    );
  } else if (status === ConnectionStatus.INACTIVE) {
    badge = (
      <Badge colorPalette={'gray'} {...style}>
        Inactive
      </Badge>
    );
  } else if (status === ConnectionStatus.ARCHIVED) {
    badge = (
      <Badge colorPalette={'red'} {...style}>
        Archived
      </Badge>
    );
  }

  return badge;
}
