import { Source } from 'types/shared-types';

export const SOURCE_CONSTANTS = [
  'BIGCOMMERCE',
  'CHARGEBEE',
  'SHOPIFY',
  'STRIPE',
  'AMAZON',
  'TIKTOK',
  'CUSTOM',
  'ZUORA',
  'APIDECK',
  'QUICKBOOKS',
  'API',
  'APPLE_APP_STORE',
  'GOOGLE_APP_STORE',
  'WALMART',
  'PAYPAL',
  'NETSUITE',
  'XERO',
  'MAXIO',
  'RECURLY',
  'SALESFORCE',
  'ETSY',
  'EBAY',
  'WIX',
  'SQUARESPACE',
  'WOOCOMMERCE',
  'MAGENTO',
  'BILLING_PLATFORM',
  'DEEL',
  'RIPPLING',
  'GUSTO',
  'OTHER',
];

export const FILE_SOURCE_MAP: {
  [key in Source]: {
    title: string;
    value: Source;
  };
} = {
  [Source.AMAZON]: {
    title: 'Amazon',
    value: Source.AMAZON,
  },
  [Source.API]: {
    title: 'Api',
    value: Source.API,
  },
  [Source.APIDECK]: {
    title: 'Apideck',
    value: Source.APIDECK,
  },
  [Source.APPLE_APP_STORE]: {
    title: 'Apple App Store',
    value: Source.APPLE_APP_STORE,
  },
  [Source.BIGCOMMERCE]: {
    title: 'Bigcommerce',
    value: Source.BIGCOMMERCE,
  },
  [Source.BILLING_PLATFORM]: {
    title: 'Billing Platform',
    value: Source.BILLING_PLATFORM,
  },
  [Source.CHARGEBEE]: {
    title: 'Chargbee',
    value: Source.CHARGEBEE,
  },
  [Source.CUSTOM]: {
    title: 'Custom',
    value: Source.CUSTOM,
  },
  [Source.DEEL]: {
    title: 'Deel',
    value: Source.DEEL,
  },
  [Source.EBAY]: {
    title: 'Ebay',
    value: Source.EBAY,
  },
  [Source.ETSY]: {
    title: 'Etsy',
    value: Source.ETSY,
  },
  [Source.GOOGLE_APP_STORE]: {
    title: 'Google App Store',
    value: Source.GOOGLE_APP_STORE,
  },
  [Source.GUSTO]: {
    title: 'Gusto',
    value: Source.GUSTO,
  },
  [Source.MAGENTO]: {
    title: 'Magento',
    value: Source.MAGENTO,
  },
  [Source.MAXIO]: {
    title: 'Maxio',
    value: Source.MAXIO,
  },
  [Source.NETSUITE]: {
    title: 'Netsuite',
    value: Source.NETSUITE,
  },
  [Source.OTHER]: {
    title: 'Other',
    value: Source.OTHER,
  },
  [Source.PAYPAL]: {
    title: 'Paypal',
    value: Source.PAYPAL,
  },
  [Source.QUICKBOOKS]: {
    title: 'Quickbooks',
    value: Source.QUICKBOOKS,
  },
  [Source.RECURLY]: {
    title: 'Recurly',
    value: Source.RECURLY,
  },
  [Source.RIPPLING]: {
    title: 'Rippling',
    value: Source.RIPPLING,
  },
  [Source.SALESFORCE]: {
    title: 'Salesforce',
    value: Source.SALESFORCE,
  },
  [Source.SHOPIFY]: {
    title: 'Shopify',
    value: Source.SHOPIFY,
  },
  [Source.SQUARESPACE]: {
    title: 'Squarespace',
    value: Source.SQUARESPACE,
  },
  [Source.STRIPE]: {
    title: 'Stripe',
    value: Source.STRIPE,
  },
  [Source.TIKTOK]: {
    title: 'Tiktok',
    value: Source.TIKTOK,
  },
  [Source.WALMART]: {
    title: 'Walmart',
    value: Source.WALMART,
  },
  [Source.WIX]: {
    title: 'Wix',
    value: Source.WIX,
  },
  [Source.WOOCOMMERCE]: {
    title: 'Woocommerce',
    value: Source.WOOCOMMERCE,
  },
  [Source.XERO]: {
    title: 'Xero',
    value: Source.XERO,
  },

  [Source.ZUORA]: {
    title: 'Zuora',
    value: Source.ZUORA,
  },
};

export enum ImportStatusEnum {
  NEW = 'NEW',
  VALIDATION_SUBMIT = 'VALIDATION_SUBMIT',
  VALIDATING = 'VALIDATING',
  VALIDATION_SUCCESS = 'VALIDATION_SUCCESS',
  VALIDATION_FAILURE = 'VALIDATION_FAILURE',
  IMPORT_STARTED = 'IMPORT_STARTED',
  IMPORTING = 'IMPORTING',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
}
