import { Box, Card, Icon, Text } from '@chakra-ui/react';
import { IntegrationIcon } from 'components/icons';
import { Button } from 'components/ui/button';
import { useNavigate } from 'react-router-dom';

export const IntegrationsCard = () => {
  const navigate = useNavigate();

  return (
    <Card.Root p={'24px'} h="100%" borderColor={'#CFD0D8'}>
      <Box w={'32px'} h={'32px'} p={'6px'} bgColor={'#ECF3FE'} borderRadius={'4px'}>
        <Icon w="6" h="6">
          <IntegrationIcon />
        </Icon>
      </Box>
      <Text mt={'16px'} fontWeight={500}>
        Integrations
      </Text>
      <Text fontSize="sm" mb={4} color={'#4B5169'} mt={1}>
        Connect your data sources and we will automatically import transactions to automate your sales tax workflows.
      </Text>
      <Button width={'155px'} colorPalette="blue" fontSize={'sm'} onClick={() => navigate('/integrations')}>
        Browse Integrations
      </Button>
    </Card.Root>
  );
};
