import { Box, Input, Text, useDisclosure } from '@chakra-ui/react';
import { useAuthInfo } from '@propelauth/react';
import { useQuery } from '@tanstack/react-query';
import {
  dataSourceQueryKeys,
  getFileSources,
  useDataSourceFileUploader,
  useImportUploadURLs,
  useValidateTransactionsFile,
} from 'apis/data-sources-apis';
import KCustomSelect from 'components/k-custom-select/k-custom-select';
import { Field } from 'components/ui/field';
import { LinkButton } from 'components/ui/link-button';
import { toaster } from 'components/ui/toaster';
import { Tooltip } from 'components/ui/tooltip';
import { FILE_SOURCE_MAP } from 'constants/source-constants';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import useTracking from 'hooks/useTracking';
import React, { useCallback, useRef, useState } from 'react';
import { FileUploadInputProps } from 'types/data-sources';
import { ErrorDataItem, PreviewRowItem, Source } from 'types/shared-types';
import { getHumanReadableString } from 'utils/enum-helpers';

import PreviewModal from './preview-modal';
import { BusinessAddressPrompt } from './update-address-prompt';

export const FileUploadInput = ({
  id,
  label,
  accept,
  note,
  skipValidation = false,
  afterSuccessfulSubmit,
  isAddressMissing,
}: FileUploadInputProps) => {
  const { open, onOpen, onClose } = useDisclosure();
  const [isLocal, setIsLocal] = useState(false);
  const [url, setUrl] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormData>(new FormData());
  const [isPresignedUrl, setIsPresignedUrl] = useState<boolean>(false);
  const [data, setData] = useState<{
    resultData: PreviewRowItem[];
    errorData: ErrorDataItem[];
    fileErrors: string | null;
  }>({
    resultData: [],
    errorData: [],
    fileErrors: null,
  });
  const [isBusinessAddressPromptOpen, setIsBusinessAddressPromptOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [source, setSource] = useState<string>('');
  const [importId, setImportId] = useState<string | null>(null);
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();
  const { orgId } = useOrg();
  const auth = useAuthInfo();
  const user_id = auth.user?.userId as string;
  const integrations = [Source.CHARGEBEE, Source.STRIPE, Source.QUICKBOOKS, Source.SHOPIFY, Source.BIGCOMMERCE];
  const { track } = useTracking();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { data: fileSources = [] } = useQuery({
    queryKey: [...dataSourceQueryKeys.fileSources(), orgId],
    queryFn: async () => await getFileSources(orgId),
    select: response => {
      const { data: sources } = response;
      return Object.keys(sources)?.map(key => ({
        label: FILE_SOURCE_MAP[key as Source]?.title ?? getHumanReadableString(key),
        value: key as Source,
      }));
    },
  });

  const { mutateAsync: doUploadTransactionFile, isPending: isUploading } = useDataSourceFileUploader({
    onSuccess: () => {
      handleSuccessNotification('Upload successfully started; we’ll email you once it’s done.');
      handleClearFile();
    },
  });
  const { mutateAsync: doValidateTransactions, isPending: isValidating } = useValidateTransactionsFile({
    onError: (error: any) => {
      if (error?.cause?.response?.status === 422) {
        return;
      } else {
        handleFailNotification(error);
      }
    },
  });
  const { mutateAsync: doGetImportUploadURLs } = useImportUploadURLs({
    onSuccess: data => {
      const [res] = data;
      let url = '';
      if (res.is_local) {
        url = res.upload_url_config[res.file_name];
        setIsLocal(true);
      } else {
        url = res.upload_url_config.url;
        const fields = res.upload_url_config.fields;
        Object.keys(fields).forEach(key => {
          formData.append(key, fields[key]);
        });
        setIsPresignedUrl(true);
      }
      const imports = res.imports;
      if (imports) {
        const import_id = imports?.id;
        setImportId(import_id);
      }
      setUrl(url);
      return data;
    },
    onError: error => {
      console.error('Error validating transactions data.', error);
      handleFailNotification(error);
    },
  });

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (!event?.dataTransfer?.files || event?.dataTransfer?.files?.length === 0) {
      toaster.create({
        type: 'error',
        title: 'Error',
        description: 'No file selected.',
      });
      return;
    }

    const droppedFile = event.dataTransfer.files[0];
    if (source && isValidChooseFile(droppedFile.type)) {
      handleFileSelection(droppedFile);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const rows_to_validate = 1000;

  const getSampleRows = (fileContent: string): string => {
    const normalizedContent = fileContent.replace(/\r\n/g, '\n');
    const rows = normalizedContent.split('\n');
    const selectedRows = rows.slice(0, rows_to_validate).join('\n');

    return selectedRows;
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      toaster.create({
        type: 'error',
        title: 'Error',
        description: 'No file selected.',
      });
      return;
    }
    const file = e.target.files[0];

    if (isAddressMissing) {
      setFile(file);
      setIsBusinessAddressPromptOpen(true);
      return;
    }

    isValidChooseFile(file.type) && handleFileSelection(e.target.files[0]);
  };

  const isValidChooseFile = (fileType: string) => {
    if (fileType === 'text/csv') {
      return true;
    }
    toaster.create({
      type: 'warning',
      title: 'Unsupported file',
      description: 'Please choose correct file. Supported type: CSV.',
    });
    return false;
  };

  const handleFileSelection = (selectedFile: File) => {
    const fileName = selectedFile?.name;
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      const fileContent = e.target.result;
      if (skipValidation) {
        await doGetImportUploadURLs({
          orgId,
          payload: {
            files: [{ file_name: fileName }],
            user_id,
            manual: true,
            source: source,
          },
        });
      } else {
        onOpen();
        const sampleRows = getSampleRows(fileContent);
        const { is_valid, result_data, errors, file_errors } = await doValidateTransactions({
          orgId,
          payload: {
            file_name: fileName,
            data: sampleRows,
            source: source,
          },
        });
        setData({
          resultData: result_data as PreviewRowItem[],
          errorData: errors as ErrorDataItem[],
          fileErrors: file_errors,
        });
        await doGetImportUploadURLs({
          orgId,
          payload: {
            files: [{ file_name: fileName }],
            user_id,
            manual: !is_valid,
            source: source,
          },
        });
      }
      setFile(selectedFile);
    };
    reader.readAsText(selectedFile);
  };

  const resumeUpload = useCallback(() => {
    if (file) {
      handleFileSelection(file);
      setFile(null);
    }
  }, [file]);

  const handleClearFile = () => {
    setData({
      resultData: [],
      errorData: [],
      fileErrors: '',
    });
    setUrl(null);
    setFormData(new FormData());
    onClose();
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (file) {
      formData.set('x-amz-meta-source', source);
      formData.set('x-amz-meta-import-id', importId ?? '');
      if (isLocal) {
        formData.append('user_id', user_id);
        formData.set('source', source);
        formData.set('import_id', importId ?? '');
      }
      formData.append('file', file);
      try {
        await doUploadTransactionFile({
          isPresignedUrl,
          url: url ?? '',
          orgId,
          formData,
        });
        afterSuccessfulSubmit && afterSuccessfulSubmit();
        track('imports data_file');
      } catch (error) {
        console.error('[ERROR]', error);
        track('imports-error');
      }
      handleClearFile();
    }
  };

  return (
    <>
      <BusinessAddressPrompt
        isOpen={isBusinessAddressPromptOpen}
        onClose={() => setIsBusinessAddressPromptOpen(false)}
        onSuccess={resumeUpload}
      />
      <PreviewModal
        isValidating={isValidating}
        isUploading={isUploading}
        isOpen={open}
        resultData={data.resultData}
        errorData={data.errorData}
        fileErrors={data.fileErrors}
        onClearFile={handleClearFile}
        onSubmit={handleSubmit}
      />
      {note}
      <Box width={'276px'}>
        <Field label="Source" required mt={'8px'} color={'#262B47'}>
          <KCustomSelect
            id="source"
            name="source"
            size="sm"
            items={fileSources}
            value={[source]}
            onValueChange={({ value }) => setSource(value[0])}
            enableSearch
            searchPlaceholder="Search source"
          />
        </Field>
      </Box>

      {integrations.includes(source as Source) && (
        <LinkButton width={'276px'} mt={4} href={`/integrations`}>
          Connect to {getHumanReadableString(source)}
        </LinkButton>
      )}

      <Box
        onDrop={handleFileDrop}
        onDragOver={handleDragOver}
        height="78px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        border="1px dashed #4285F4"
        borderRadius="2px"
        p={'16px'}
        opacity={!source ? 0.5 : 1}
        mt={3}
      >
        <Tooltip disabled={!!source} content={'Please select source'}>
          <Box>
            <Text fontSize="sm">
              Drag and Drop your files here or{' '}
              <Text
                as="span"
                color={!source ? 'secondary.900' : 'secondary.500'}
                textDecoration={'underline'}
                cursor={!source ? 'not-allowed' : 'pointer'}
                onClick={() => {
                  if (source) {
                    fileInputRef.current?.click();
                  }
                }}
              >
                Choose File
                <Input
                  ref={fileInputRef}
                  type="file"
                  id={id}
                  accept={accept}
                  display="none"
                  onChange={handleFileChange}
                  onClick={(e: any) => {
                    e.target.value = '';
                  }}
                  disabled={!source}
                />
              </Text>
            </Text>

            <Text fontSize="xs" color="gray.600" textAlign="center">
              {label}
            </Text>
          </Box>
        </Tooltip>
      </Box>
    </>
  );
};
