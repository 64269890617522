import { HStack, Table, Text } from '@chakra-ui/react';
import { Tooltip } from 'components/ui/tooltip';
import { ImportStatusEnum } from 'constants/source-constants';
import { FileImports } from 'types/data-sources';
import { toDateShort } from 'utils/dates';

import { ImportStatusBadge } from './import-status-badge';

type HistoryTableRowProps = {
  history: FileImports;
};

export const HistoryTableRow = ({ history }: HistoryTableRowProps) => {
  const { parent_file_name, num_rows_total, num_transactions_imported, created_at, status, error_data } = history;

  return (
    <Table.Row>
      <Table.Cell>
        <Text lineClamp={1}>{parent_file_name}</Text>
      </Table.Cell>

      <Table.Cell>
        <HStack gap={1}>
          <Text>{num_rows_total}</Text>
        </HStack>
      </Table.Cell>

      <Table.Cell>
        <Text>{num_transactions_imported}</Text>
      </Table.Cell>

      <Table.Cell>
        <Text>{toDateShort(created_at)}</Text>
      </Table.Cell>

      <Table.Cell>
        <Tooltip
          content={
            status === ImportStatusEnum.FAILURE && error_data ? `${error_data?.length}  Transactions with errors` : ''
          }
        >
          <ImportStatusBadge status={status} />
        </Tooltip>
      </Table.Cell>
    </Table.Row>
  );
};
