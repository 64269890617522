import { Box, Card, Flex, HStack, Icon, Text } from '@chakra-ui/react';
import { useOrgDetailsQuery } from 'apis/organizations-apis';
import { HelpIcon, HistoryIcon, UploadIcon } from 'components/icons';
import { LinkButton } from 'components/ui/link-button';
import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from 'components/ui/menu';
import { useOrg } from 'hooks/useOrg';
import { useState } from 'react';

import { FileUploadInput } from './components/file-upload-input';
import { FileUploadHistory } from './components/history';
import ImportExampleModal from './components/import-example-modal';
import { SupportModal } from './components/support/support-modal';

const FileUpload = () => {
  const { orgId } = useOrg();
  const [isOpenSupportModal, setIsOpenSupportModal] = useState(false);
  const [isOpenHistoryDrawer, setIsOpenHistoryDrawer] = useState(false);
  const { data } = useOrgDetailsQuery(orgId);
  const isAddressMissing = data ? !data.company_country_code || !data.company_postal_code : true;

  return (
    <Card.Root p={'24px'} height={'100%'} borderColor={'#CFD0D8'}>
      <HStack justifyContent={'space-between'}>
        <Box w={'32px'} h={'32px'} px={'5px'} py={'5px'} bgColor={'#ECF3FE'} borderRadius={'4px'}>
          <Icon w="6" h="6">
            <UploadIcon />
          </Icon>
        </Box>

        <Flex gap={2} alignItems={'center'}>
          <MenuRoot>
            <MenuTrigger asChild>
              <HelpIcon width="20px" cursor={'pointer'} />
            </MenuTrigger>
            <MenuContent>
              <MenuItem
                _hover={{ bg: '#EFEFF3' }}
                as={'button'}
                fontSize={'sm'}
                value="send_a_file"
                onClick={() => setIsOpenSupportModal(true)}
              >
                Send Support a File
              </MenuItem>
              <MenuItem asChild value="help_doc" _hover={{ bg: '#EFEFF3' }} fontSize={'sm'}>
                <LinkButton
                  href="https://kintsugi.readme.io/docs/file-upload"
                  target="_blank"
                  bg={'white'}
                  fontWeight={'normal'}
                >
                  Help Documentation
                </LinkButton>
              </MenuItem>
            </MenuContent>
          </MenuRoot>
          <HistoryIcon width="20px" cursor="pointer" onClick={() => setIsOpenHistoryDrawer(true)} />
        </Flex>
      </HStack>

      <Text mt={'16px'} fontWeight={500}>
        File Upload
      </Text>

      <Text fontSize="sm" color={'#4B5169'} mt={1}>
        Load historical data that can be used to migrate or calculate your nexus and tax exposure at a point in time.{' '}
        <ImportExampleModal />
      </Text>

      <FileUploadInput
        isAddressMissing={isAddressMissing}
        id="file"
        accept=".csv"
        label="Max file size: 100MB. Supported type: CSV"
      />

      {isOpenSupportModal && <SupportModal isOpen={isOpenSupportModal} onClose={() => setIsOpenSupportModal(false)} />}
      {isOpenHistoryDrawer && (
        <FileUploadHistory isOpen={isOpenHistoryDrawer} onClose={() => setIsOpenHistoryDrawer(false)} />
      )}
    </Card.Root>
  );
};

export default FileUpload;
