import { Flex, Input, Text } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { archiveTransactionsByConnectionId, CONNECTIONS_STATE_KEY, updateConnection } from 'apis/connections';
import { Button } from 'components/ui/button';
import { Checkbox } from 'components/ui/checkbox';
import {
  DialogBackdrop,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { Field } from 'components/ui/field';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { useEffect } from 'react';
import { ConnectionStatus } from 'types/shared-types';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  connectionId: Yup.string().required('Connection ID is required'),
  archiveTransactions: Yup.boolean(),
  confirmation: Yup.string().when('archiveTransactions', ([archiveTransactions], schema) => {
    if (archiveTransactions) {
      return Yup.string()
        .required('Confirmation is required')
        .equals(['CONFIRM'], `Please ensure the text you entered is 'CONFIRM' and contains no other characters.`);
    }
    return schema;
  }),
});

export const ArchiveConnection = ({ connectionId, onClose }: { connectionId: string; onClose: () => void }) => {
  const { orgId } = useOrg();

  const { mutateAsync: archiveTransactionsForConnection } = useMutation({
    mutationFn: async (connection_id: string) => {
      const { data } = await archiveTransactionsByConnectionId(connection_id, orgId);
      return data;
    },
    onSuccess: data => {
      onClose();
      queryClient.invalidateQueries({ queryKey: [CONNECTIONS_STATE_KEY] });
      handleSuccessNotification(data);
    },
    onError: error => {
      handleFailNotification(error);
    },
  });

  const {
    values: { archiveTransactions, confirmation },
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      connectionId: connectionId,
      archiveTransactions: false,
      confirmation: '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async ({ connectionId, archiveTransactions }) => {
      await archiveConnection({ connection_id: connectionId, status: ConnectionStatus.ARCHIVED });
      if (archiveTransactions) {
        await archiveTransactionsForConnection(connectionId);
      }
      onClose();
    },
  });

  const isConfirmationInvalid = !!(touched.confirmation && errors.confirmation);

  const queryClient = useQueryClient();
  const { handleSuccessNotification, handleFailNotification } = useHandleNotification();

  const { mutateAsync: archiveConnection, isPending } = useMutation({
    mutationFn: (payload: { connection_id: string; status: string }) => {
      return updateConnection(payload.connection_id, orgId, { status: payload.status });
    },
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries({ queryKey: [CONNECTIONS_STATE_KEY] });
      handleSuccessNotification('Connection successfully updated.');
    },
    onError: error => {
      handleFailNotification(error);
    },
  });

  useEffect(() => {
    resetForm();
  }, []);

  return (
    <DialogRoot open={Boolean(connectionId)}>
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Are you sure you want to archive connection?</DialogTitle>
        </DialogHeader>
        <DialogBody pt={1}>
          <Text mb={4}>Once archived we will no longer sync data from this connection.</Text>
          <Checkbox
            name="archiveTransactions"
            checked={archiveTransactions}
            onCheckedChange={({ checked }) => {
              setFieldValue('archiveTransactions', checked);
              if (!checked) {
                setFieldValue('confirmation', '');
              }
            }}
          >
            Archive all Transactions and customers
          </Checkbox>
          {archiveTransactions && (
            <Field
              label="Enter 'CONFIRM' to proceed"
              invalid={isConfirmationInvalid}
              errorText={errors.confirmation}
              mt={4}
              required
            >
              <Input
                id="confirmation"
                name="confirmation"
                value={confirmation}
                onChange={handleChange}
                placeholder={'CONFIRM'}
              />
            </Field>
          )}
        </DialogBody>
        <DialogFooter>
          <Flex gap={2}>
            <Button variant={'outline'} onClick={onClose}>
              Cancel
            </Button>
            <Button
              loading={isPending}
              disabled={archiveTransactions && confirmation !== 'CONFIRM'}
              colorPalette="red"
              width={'90px'}
              onClick={() => handleSubmit()}
            >
              Confirm
            </Button>
          </Flex>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};
