import { Box, createListCollection, Spinner, Text } from '@chakra-ui/react';
import SearchInput, { SearchInputRef } from 'components/SearchInput';
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from 'components/ui/select';
import { useEffect, useMemo, useRef, useState } from 'react';
import { KCustomSelectProps } from 'types/kCustomSelectType';

export const KCustomSelect = ({
  placeholder = 'Select',
  items,
  enableSearch = false,
  searchPlaceholder = 'Search',
  isExternalSearch = false, // TODO: Still need to work to support server side filtering
  isDataLoading = false,
  searchQuery = '',
  onChangeSearchQuery,
  name,
  value,
  itemToStringField,
  itemToValueField,
  onValueChange,
  customSelectItemContent,
  ...selectRootProps
}: KCustomSelectProps) => {
  const searchInputRef = useRef<SearchInputRef>(null);
  const [internalItems, setInternalItems] = useState<KCustomSelectProps['items']>(items);
  const [internalSearchQuery, setInternalSearchQuery] = useState<string>('');

  useEffect(() => {
    setInternalItems(items);
  }, [items]);

  useEffect(() => {
    if (isExternalSearch && searchQuery) {
      setInternalSearchQuery(searchQuery);
    }
  }, [searchQuery]);

  const itemCollection = useMemo(() => {
    if (itemToStringField || itemToValueField) {
      return createListCollection({
        items: internalItems ?? [],
        itemToString: (item: any) => item[itemToStringField ?? 'label'],
        itemToValue: (item: any) => item[itemToValueField ?? 'value'],
      });
    }
    return createListCollection({
      items: internalItems ?? [],
    });
  }, [internalItems]);

  const onChangeQuery = (query: string) => {
    if (isExternalSearch && onChangeSearchQuery) {
      return onChangeSearchQuery(query);
    }
    if (!query) {
      return setInternalItems(items);
    }

    setInternalSearchQuery(query);
    setInternalItems(filterItemsByQuery(query));
  };

  const filterItemsByQuery = (query: string): Array<Record<string, any>> => {
    if (!query || !internalItems?.length) return internalItems;

    return internalItems?.filter(item => {
      return item.label.toLowerCase().includes(query.toLowerCase());
    });
  };

  return (
    <SelectRoot
      {...selectRootProps}
      name={name}
      collection={itemCollection}
      onValueChange={e => {
        if (onValueChange) {
          onValueChange(e);
        }
        setInternalSearchQuery('');
        searchInputRef?.current?.resetQuery();
      }}
      value={value}
      disabled={isDataLoading}
    >
      <SelectTrigger>
        <SelectValueText placeholder={placeholder} />
      </SelectTrigger>
      <SelectContent
        style={{
          maxHeight: '306px',
          overflowY: 'auto',
          paddingTop: '1px',
        }}
        portalled={false}
      >
        {enableSearch && (
          <Box position="sticky" top={0} zIndex={1} bg="white">
            <SearchInput
              ref={searchInputRef}
              query={internalSearchQuery ?? ''}
              setQuery={onChangeQuery}
              placeholder={searchPlaceholder}
              styles={{ width: '100%' }}
            />
          </Box>
        )}
        {itemCollection?.items?.map(item =>
          customSelectItemContent ? (
            customSelectItemContent(item)
          ) : (
            <SelectItem key={item[itemToValueField ?? 'value']} item={item}>
              {item[itemToStringField ?? 'label']}
            </SelectItem>
          )
        )}
        {enableSearch &&
          itemCollection?.items?.length === 0 &&
          (isDataLoading ? (
            <Box textAlign={'center'} p={2} mt={4} height="100%">
              <Spinner />
            </Box>
          ) : (
            <Text textAlign={'center'} p={2} mt={4} height="100%">
              No items found
            </Text>
          ))}
      </SelectContent>
    </SelectRoot>
  );
};

export default KCustomSelect;
