import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { apiUpload } from 'apis/file-upload-apis';
import { FileImports, FileSourceType, TFileValidatorResponse, TImportUploadUrl } from 'types/data-sources';
import { ApiResponse, TableFilter } from 'types/shared-types';

import api from './api';

export const dataSourceQueryKeys = {
  base: ['dataSource'] as const,
  fileSources: () => [...dataSourceQueryKeys.base, 'fileSources'] as const,
  fileHistory: () => [...dataSourceQueryKeys.base, 'fileHistory'] as const,
};

export const useDataSourceFileUploader = (
  options: Partial<
    UseMutationOptions<
      unknown,
      unknown,
      {
        isPresignedUrl: boolean;
        url: string;
        orgId: string;
        formData: FormData;
        progressCallback?: (progress: number) => void;
        userId?: string;
      }
    >
  > = {}
) => {
  return useMutation<
    unknown,
    unknown,
    {
      isPresignedUrl: boolean;
      url: string;
      orgId: string;
      formData: FormData;
      progressCallback?: (progress: number) => void;
      userId?: string;
    }
  >({
    mutationFn: async ({ isPresignedUrl, url, formData, orgId, progressCallback, userId }) => {
      return await apiUpload(
        orgId,
        url,
        formData,
        isPresignedUrl,
        {
          headers: {
            redirect: 'follow',
          },
        },
        progressCallback,
        userId
      );
    },
    ...options,
  });
};

export const useValidateTransactionsFile = (
  options: Partial<
    UseMutationOptions<
      TFileValidatorResponse,
      unknown,
      {
        orgId: string;
        payload: {
          file_name: string;
          data: unknown;
          source: string;
        };
      }
    >
  > = {}
) => {
  return useMutation<
    TFileValidatorResponse,
    unknown,
    {
      orgId: string;
      payload: {
        file_name: string;
        data: unknown;
        source: string;
      };
    }
  >({
    mutationFn: async ({ orgId, payload }) => {
      try {
        const { data } = await api.post('/v1/import/validate', payload, {
          headers: {
            'x-organization-id': orgId,
          },
        });
        return data;
      } catch (error) {
        if ((error as any)?.response?.status === 422) {
          return (error as any).response.data?.detail;
        } else {
          throw new Error('Data Source Import', { cause: error });
        }
      }
    },
    ...options,
  });
};

export const useImportUploadURLs = (
  options: Partial<
    UseMutationOptions<
      TImportUploadUrl[],
      unknown,
      {
        orgId: string;
        payload: {
          files: {
            file_name: string;
          }[];
          manual: boolean;
          user_id: string;
          source: string;
          imports?: {
            id: string;
          };
        };
      }
    >
  > = {}
) => {
  return useMutation<
    TImportUploadUrl[],
    unknown,
    {
      orgId: string;
      payload: {
        files: {
          file_name: string;
        }[];
        manual: boolean;
        user_id: string;
        source: string;
        imports?: {
          id: string;
        };
      };
    }
  >({
    mutationFn: async ({ orgId, payload }) => {
      try {
        const { data } = await api.post(`/v1/import/upload-urls`, payload, {
          headers: {
            'x-organization-id': orgId,
          },
        });
        return data;
      } catch (error) {
        throw new Error('Data Source Import', { cause: error });
      }
    },
    ...options,
  });
};

export const getFileSources = (orgId: string, filterByOrganization?: boolean) => {
  return api.get<FileSourceType>('/v1/transactions/sources', {
    headers: {
      'x-organization-id': orgId,
    },
    params: {
      filter_by_organization: filterByOrganization,
    },
  });
};

export const getFileUploadHistory = (orgId: string, params?: TableFilter) => {
  return api.get<ApiResponse<FileImports>>('/v1/imports', {
    headers: {
      'x-organization-id': orgId,
    },
    params,
  });
};

export const updateTaxCalculation = (orgId: string, connectionId: string, setEnable: boolean) => {
  return api.put(
    `/v1/connections/${connectionId}/set_tax_collection`,
    {},
    {
      headers: {
        'x-organization-id': orgId,
      },
      params: {
        set_enable: setEnable,
      },
    }
  );
};
