export const tooltips = {
  related_external_id: 'ID related to transaction_external_id,\n required for when transaction types credit_note.',
  transaction_external_id: '• Unique id for the transaction, invoice, or order\n• This field is required',
  status: 'Status of the transactions (COMMITTED, PENDING, FULLY_REFUNDED)',
  transaction_source: 'Source of the transactions (OTHER as default)',
  date: '• Date the transaction occurred\n• This field is required',
  total_amount: '• Total amount of the transaction\n• This field is required',
  total_tax_amount_calculated: 'Total tax amount of the transaction calculated',
  currency: 'Use three-letter ISO codes to represent currencies (e.g., USD for US Dollars, JPY for Japanese Yen).',
  description: 'Description of the Transaction',
  customer_id: '• Unique ID for a customer\n• This field is required',
  customer_name: 'Name of the customer',
  customer_email: 'Email of the customer',
  marketplace: 'If this transaction is from a marketplace facilitator',
  line_item_id: 'Line item id - Unique id of the transaction item',
  product_external_id:
    '• Unique id of a product stored in your order management or billing system\n• This field is required',
  product_name: 'Name of the product',
  product_description: 'Description of the product',
  amount: '• Amount of the item\n• This field is required',
  tax_amount: 'Tax amount of the item',
  quantity: 'Quantity of the item',
  exempt: 'If the item is exempt from tax',
  ship_to_phone: 'Shipping Address Phone',
  ship_to_street_line_1: 'Shipping address line 1',
  ship_to_street_line_2: 'Shipping address line 2',
  ship_to_city: 'Shipping City',
  ship_to_state: '• Shipping State\n• This field is required',
  ship_to_postal_code: '• Shipping Postal Code\n• This field is required',
  ship_to_country: 'Shipping Country',
  bill_to_phone: 'Billing phone',
  bill_to_street_line_1: 'Billing address line 1',
  bill_to_street_line_2: 'Billing address line 2',
  bill_to_city: 'Billing City',
  bill_to_state: '• Billing State\n• This field is required',
  bill_to_postal_code: '• Billing postal code\n• This field is required',
  bill_to_country: 'Billing country',
  transaction_type: 'Type of the transaction.',
  operation: 'Operation to perform on the transaction.',
};
