import { Badge } from '@chakra-ui/react';
import { ImportStatusEnum } from 'constants/source-constants';

export const ImportStatusBadge = ({ status }: Readonly<{ status: ImportStatusEnum }>) => {
  if (!status) {
    return null;
  }

  switch (status) {
    case ImportStatusEnum.SUCCESS:
    case ImportStatusEnum.VALIDATION_SUCCESS:
      return (
        <Badge colorPalette={'green'} width={'87px'} color={'#1C4532'} fontWeight={'normal'}>
          Success
        </Badge>
      );
    case ImportStatusEnum.FAILURE:
    case ImportStatusEnum.VALIDATION_FAILURE:
      return (
        <Badge colorPalette={'red'} width={'87px'} color={'#63171B'} fontWeight={'normal'}>
          Failed
        </Badge>
      );
  }

  return (
    <Badge colorPalette={'gray'} width={'87px'} fontWeight={'normal'}>
      Submitted
    </Badge>
  );
};
