import { Flex, Table, Text } from '@chakra-ui/react';
import { InfoIcon } from 'components/icons';
import { Tooltip } from 'components/ui/tooltip';
import React from 'react';
import { ErrorDataItem } from 'types/shared-types';

interface PreviewErrorRowProps {
  idx: number;
  errorItem: ErrorDataItem;
  hideColumn: string[];
  headers: string[];
}

const PreviewErrorRow = ({ idx, errorItem, hideColumn, headers }: PreviewErrorRowProps) => {
  return (
    <Table.Row key={idx}>
      {headers
        .filter(header => !hideColumn.includes(header))
        .map((header, iidx) => {
          const value = errorItem.row[header] || ''; // Handle missing headers as backend doesn't handle this
          const errorObj = errorItem.error.find(
            error => error.loc.includes(header) || (error.type === 'value_error' && error.loc.includes(header))
          );
          const isInLoc = errorObj !== undefined;
          const errorMsg = isInLoc ? errorObj.msg : '';

          return (
            <Table.Cell
              key={`${idx}_${iidx}_${header}`}
              border={'1px solid var(--gray-50, #EFEFF3)'}
              borderLeft={'none'}
              bg={isInLoc ? '#FED7D7' : '#FFF5F5'}
            >
              {value || !isInLoc ? (
                <Tooltip content={errorMsg} aria-label={`Error for ${header}: ${errorMsg}`} disabled={!errorMsg}>
                  <Text>{value}</Text>
                </Tooltip>
              ) : (
                <Flex align="center" gap={1}>
                  <Text fontSize="sm">Required field</Text>
                  <Tooltip content={errorMsg} aria-label={`Error for ${header}: ${errorMsg}`}>
                    <InfoIcon color="red.500" boxSize={3} />
                  </Tooltip>
                </Flex>
              )}
            </Table.Cell>
          );
        })}
    </Table.Row>
  );
};

export default React.memo(PreviewErrorRow);
