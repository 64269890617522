import { Box, Flex, HStack, Input, Text, VStack } from '@chakra-ui/react';
import { RemoveIcon } from 'components/icons';
import { toaster } from 'components/ui/toaster';
import React, { useRef } from 'react';

type SupportFileUploadType = {
  id: string;
  accept: string;
  label: string;
  chooseFiles: any;
  handleChooseFile: (files: File[]) => void;
};

export const SupportFileUpload = ({ id, accept, label, chooseFiles, handleChooseFile }: SupportFileUploadType) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const dropFile = event.dataTransfer.files[0];
    const dropFileType = dropFile.type;
    if (dropFile && isValidChooseFile(dropFileType)) {
      handleFileSelection(dropFile);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files || e?.target?.files?.length === 0) {
      toaster.create({
        type: 'error',
        title: 'Error',
        description: 'No file selected.',
      });
      return;
    }

    const file = e.target.files[0];
    const fileType = file.type;

    if (file && isValidChooseFile(fileType)) {
      handleFileSelection(file);
    }
  };

  const isValidChooseFile = (fileType: string) => {
    if (fileType === 'text/csv') {
      return true;
    }
    toaster.create({
      type: 'warning',
      title: 'Unsupported file',
      description: 'Please choose correct file. Supported type: CSV.',
    });
    return false;
  };

  const handleFileSelection = (file: File) => {
    const updatedFiles = [...chooseFiles, file];
    handleChooseFile(updatedFiles);
  };

  const handleRemoveFileSelection = (indexToRemove: number) => {
    const updatedArray = [...chooseFiles.slice(0, indexToRemove), ...chooseFiles.slice(indexToRemove + 1)];
    handleChooseFile(updatedArray);
  };

  return (
    <VStack alignItems={'flex-start'} gap={2}>
      <Box
        onDrop={handleFileDrop}
        onDragOver={handleDragOver}
        height="78px"
        display="flex"
        width={'full'}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        border="1px dashed #4285F4"
        borderRadius="2px"
        p={'16px'}
      >
        <HStack>
          Drag and Drop your files here or
          <Text
            color={'secondary.500'}
            textDecoration={'underline'}
            cursor="pointer"
            onClick={() => fileInputRef.current?.click()}
          >
            Choose File
            <Input
              ref={fileInputRef}
              type="file"
              id={id}
              accept={accept}
              multiple
              display="none"
              onChange={handleFileChange}
              onClick={(e: any) => {
                e.target.value = '';
              }}
            />
          </Text>
        </HStack>
        <Text fontSize="xs" color="gray.600">
          {label}
        </Text>
      </Box>

      <Box>
        {chooseFiles.map((file: File, index: number) => (
          <Flex key={index} gap={2} alignItems={'center'} pt={2}>
            <Text color="#4B5169" fontSize={'xs'} lineClamp={1}>
              {file.name}
            </Text>
            <RemoveIcon mt={3} cursor="pointer" onClick={() => handleRemoveFileSelection(index)} />
          </Flex>
        ))}
      </Box>
    </VStack>
  );
};
