import { Flex, HStack, Skeleton, Table, Text, VStack } from '@chakra-ui/react';
import AlertBanner from 'components/alert/alert';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { Switch } from 'components/ui/switch';
import TableContainer from 'components/ui/table-container';
import { Tooltip } from 'components/ui/tooltip';
import debounce from 'lodash/debounce';
import { useMemo, useState } from 'react';
import { ErrorDataItem, PreviewRowItem } from 'types/shared-types';

import { tooltips } from './examples/header-tooltips';
import PreviewErrorRow from './preview-error-row';
import PreviewRow from './preview-row';

const hideColumn = ['transaction_source'];
const BATCH_SIZE = 50;
const SCROLL_THRESHOLD = 0.9;

type PreviewModalProps = {
  isValidating: boolean;
  isUploading: boolean;
  isOpen: boolean;
  resultData: PreviewRowItem[];
  errorData: ErrorDataItem[];
  onClearFile: () => void;
  onSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void;
  fileErrors: string | null;
};

const getHeaders = (errorData: ErrorDataItem[], resultData: PreviewRowItem[]) => {
  if (!resultData?.length && !errorData?.length) return [];
  if (resultData?.length > 0 && resultData[0]) {
    return Object.keys(resultData[0]).filter(header => !hideColumn.includes(header));
  } else if (errorData?.length > 0 && errorData[0]?.row) {
    return Object.keys(errorData[0].row).filter(header => !hideColumn.includes(header));
  }
  return [];
};

const PreviewModal = ({
  isValidating,
  isUploading,
  isOpen,
  resultData = [],
  errorData,
  onClearFile,
  onSubmit,
  fileErrors,
}: PreviewModalProps) => {
  const [showOnlyErrorRows, setShowOnlyErrorRows] = useState<boolean>(false);
  const [rows, setRows] = useState<PreviewRowItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const headers = useMemo(() => getHeaders(errorData, resultData), [errorData, resultData]);

  const filteredData = useMemo(() => {
    return resultData.map(row => {
      const filteredRow = { ...row };
      hideColumn.forEach(column => {
        delete filteredRow[column as keyof PreviewRowItem];
      });
      return filteredRow;
    });
  }, [resultData]);

  debounce(event => {
    const target = event.target;
    if (rows.length >= filteredData.length || showOnlyErrorRows) {
      return;
    }
    const scrollPercentage = (target.scrollTop + target.clientHeight) / target.scrollHeight;

    if (scrollPercentage > SCROLL_THRESHOLD) {
      setIsLoading(true);

      requestAnimationFrame(() => {
        setRows(prevRows => [...prevRows, ...filteredData.slice(prevRows.length, prevRows.length + BATCH_SIZE)]);
        setIsLoading(false);
      });
    }
  }, 100);

  const toggleShowOnlyErrorRows = () => {
    setShowOnlyErrorRows(!showOnlyErrorRows);
    setRows([]);
  };

  const handleCloseModal = () => {
    if (isUploading) {
      return;
    }
    onClearFile();
    setShowOnlyErrorRows(false);
    setRows([]);
  };

  return (
    <DialogRoot
      scrollBehavior="inside"
      placement="center"
      size={'xl'}
      open={isOpen}
      onOpenChange={({ open }) => {
        if (!open) {
          handleCloseModal();
        }
      }}
    >
      <DialogBackdrop />
      <DialogContent>
        <DialogHeader display="flex" justifyContent="space-between">
          <DialogTitle>Preview</DialogTitle>
        </DialogHeader>
        <DialogCloseTrigger top={4} />
        <DialogBody>
          {isValidating === true && (
            <VStack gap={4}>
              {Array.from({ length: 15 }, (_, index) => (
                <Skeleton key={index} height="30px" width="full" />
              ))}
            </VStack>
          )}
          {isValidating === false && (
            <>
              <Text color={'#383D58'} fontSize="xs" lineHeight={'20px'} mb={2}>
                We will only show up to 1000 records in this preview. The full file will still be imported.
              </Text>

              {fileErrors && <AlertBanner message={fileErrors} colorPalette={'red'} width={'700px'} height={'36px'} />}

              {errorData && errorData.length > 0 && (
                <Flex justifyContent="space-between" alignItems="center" mb={2}>
                  <AlertBanner
                    message={
                      errorData.length > 1
                        ? `${errorData.length} rows contain errors`
                        : `${errorData.length} row contains error`
                    }
                    colorPalette={'red'}
                    width={{ base: '400px', md: '400px' }}
                    height={'36px'}
                  />

                  <HStack>
                    <Text fontSize={'14px'} fontWeight={500}>
                      Show only rows with error
                    </Text>
                    <Switch colorPalette="blue" onCheckedChange={toggleShowOnlyErrorRows} checked={showOnlyErrorRows} />
                  </HStack>
                </Flex>
              )}
              <TableContainer>
                <Table.ScrollArea>
                  <Table.Root size={'sm'}>
                    <Table.Header>
                      <Table.Row>
                        {headers.map(header => (
                          <Table.ColumnHeader
                            border={'1px solid var(--gray-50, #EFEFF3)'}
                            borderTop={'none'}
                            borderLeft={'none'}
                            key={header}
                          >
                            <Tooltip content={tooltips[header as keyof typeof tooltips]}>
                              <Text fontSize={'12px'} fontWeight={500}>
                                {header.toUpperCase()}
                              </Text>
                            </Tooltip>
                          </Table.ColumnHeader>
                        ))}
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {showOnlyErrorRows ? (
                        <>
                          {errorData &&
                            Array.isArray(errorData) &&
                            errorData.map((errorItem, idx) => (
                              <PreviewErrorRow
                                key={idx}
                                idx={idx}
                                errorItem={errorItem}
                                hideColumn={hideColumn}
                                headers={headers}
                              />
                            ))}
                        </>
                      ) : (
                        <>
                          {errorData &&
                            Array.isArray(errorData) &&
                            errorData.map((errorItem, idx) => (
                              <PreviewErrorRow
                                key={idx}
                                idx={idx}
                                errorItem={errorItem}
                                hideColumn={hideColumn}
                                headers={headers}
                              />
                            ))}
                          {(rows.length > 0 ? rows : filteredData.slice(0, 20)).map((row, idx) => (
                            <PreviewRow key={idx} row={row} idx={idx} />
                          ))}
                          {isLoading && (
                            <Table.Row>
                              <Table.Cell colSpan={headers.length} position="relative">
                                <Skeleton height="50px" width="full"></Skeleton>
                                <Text color={'initial'} position="absolute" top={4} left={6}>
                                  Loading More Rows...
                                </Text>
                              </Table.Cell>
                            </Table.Row>
                          )}
                        </>
                      )}
                    </Table.Body>
                  </Table.Root>
                </Table.ScrollArea>
              </TableContainer>
            </>
          )}
        </DialogBody>
        {fileErrors ||
          (errorData && errorData.length === 0 && (
            <DialogFooter>
              <HStack alignItems={'center'}>
                <Button
                  disabled={isUploading}
                  variant="outline"
                  color="secondary"
                  onClick={handleCloseModal}
                  width={'125px'}
                >
                  Cancel
                </Button>
                <Button
                  disabled={isValidating || !!fileErrors}
                  loading={isUploading}
                  variant="solid"
                  colorPalette="blue"
                  onClick={event => onSubmit(event)}
                  width={'125px'}
                >
                  Upload
                </Button>
              </HStack>
            </DialogFooter>
          ))}
      </DialogContent>
    </DialogRoot>
  );
};

export default PreviewModal;
