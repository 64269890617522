import { Flex } from '@chakra-ui/react';
import AppHeader from 'app/app-header';

import { IntegrationsCard } from './components/integrations/card';
import { ManageIntegrations } from './components/manage-integrations';
import FileUpload from './file-upload';

export default function Import() {
  return (
    <>
      <AppHeader />
      <Flex data-onboarding="data-import" flexWrap={{ base: 'nowrap', md: 'nowrap', sm: 'wrap' }} gap={6}>
        <Flex>
          <IntegrationsCard />
        </Flex>
        <Flex>
          <FileUpload />
        </Flex>
      </Flex>
      <ManageIntegrations />
    </>
  );
}
