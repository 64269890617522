import { Text } from '@chakra-ui/react';
import { useDataSourceFileUploader } from 'apis/data-sources-apis';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { useState } from 'react';

import { SupportFileUpload } from './support-file-upload';

type SupportModalType = {
  isOpen: boolean;
  onClose: () => void;
};

export const SupportModal = ({ isOpen, onClose }: SupportModalType) => {
  const { orgId } = useOrg();
  const { handleSuccessNotification } = useHandleNotification();
  const [chooseFiles, setChooseFiles] = useState<File[]>([]);
  const isSubmitBtnDisable = !chooseFiles.length;

  const { mutateAsync: doUploadSupportFile, isPending: isUploading } = useDataSourceFileUploader({
    onSuccess: () => {
      handleSuccessNotification('Upload successfully started; we’ll email you once it’s done.');
      setChooseFiles([]);
      onClose();
    },
  });

  const uploadSupportFiles = async () => {
    if (chooseFiles.length) {
      const formData = new FormData();

      // Append each file to the FormData object
      Array.from(chooseFiles).forEach(file => {
        formData.append('files', file); // 'files' is the key name used in the backend
      });

      try {
        await doUploadSupportFile({
          isPresignedUrl: false,
          url: '/v1/import/internal-manual-upload',
          orgId,
          formData,
        });
      } catch (error) {
        console.error('[ERROR]', error);
      }
    }
  };

  return (
    <DialogRoot open={isOpen} size={'md'} placement="center">
      <DialogBackdrop />
      <DialogContent>
        <DialogCloseTrigger onClick={onClose} />
        <DialogHeader pb={2}>
          <DialogTitle fontWeight={'500'}>Send Support a File</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <Text color={'#4B5169'} pb={4}>
            We&apos;ll work with you to import the file. Depending on the file, it may take a few days.
          </Text>
          <SupportFileUpload
            id="file"
            accept=".csv"
            label="Max file size: 10MB. Supported type: CSV"
            chooseFiles={chooseFiles}
            handleChooseFile={setChooseFiles}
          />
        </DialogBody>

        <DialogFooter gap={4}>
          <Button variant={'outline'} color={'secondary'} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant={'solid'}
            width="90px"
            disabled={isSubmitBtnDisable}
            onClick={uploadSupportFiles}
            loading={isUploading}
          >
            Submit
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};
