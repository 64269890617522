import { Box, Flex, HStack, Table, Text, useDisclosure } from '@chakra-ui/react';
import AlertBanner from 'components/alert/alert';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogHeader,
  DialogRoot,
} from 'components/ui/dialog';
import TableContainer from 'components/ui/table-container';
import { Tooltip } from 'components/ui/tooltip';
import copy from 'copy-to-clipboard';
import papaparse from 'papaparse';
import { useState } from 'react';
import { MdOutlineContentCopy, MdOutlineFileDownload, MdOutlineHelpOutline } from 'react-icons/md';

import data from './examples/example.json';
import { tooltips } from './examples/header-tooltips';
import { SupportModal } from './support/support-modal';

const ImportExamples = () => {
  const { open, onOpen, onClose } = useDisclosure();
  const [showFileUpload, setShowFileUpload] = useState(false);

  const handleFileUploadClick = () => {
    onClose();
    setShowFileUpload(true);
  };

  const handleCopyContent = () => {
    copy(papaparse.unparse(data));
  };

  const asterikIcon = (
    <Box as="span" color={'#E53E3E'}>
      *
    </Box>
  );

  const mandatoryColumns = [
    'transaction_external_id',
    'date',
    'total_amount',
    'customer_id',
    'ship_to_state',
    'ship_to_postal_code',
    'bill_to_state',
    'bill_to_postal_code',
    'product_external_id',
    'amount',
  ];
  const numericColumns = ['amount', 'tax_amount'];

  const handleDownload = () => {
    const csv = papaparse.unparse(data);
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'example-transactions.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Text
        as="span"
        color={'secondary.500'}
        fontSize="sm"
        textDecoration={'underline'}
        onClick={onOpen}
        cursor={'pointer'}
      >
        File Upload Template
      </Text>
      <DialogRoot scrollBehavior="inside" placement="center" open={open}>
        <DialogBackdrop />
        <DialogContent maxW="62rem">
          <DialogHeader display="flex" fontSize="lg" fontWeight="500" lineHeight="26px" justifyContent="space-between">
            File Upload Template
            <Text
              display={'inline-flex'}
              justifyContent={'center'}
              alignItems={'center'}
              gap={1}
              textDecoration={'none'}
              onClick={handleFileUploadClick}
              cursor={'pointer'}
              mr={10}
              fontSize={'14px'}
              fontWeight={'400'}
            >
              <MdOutlineHelpOutline size={'18px'} />
              Send Support a File
            </Text>
          </DialogHeader>
          <DialogCloseTrigger top={4} onClick={onClose} />
          <DialogBody mb={4} gap={2}>
            <>
              <Flex justify={'space-between'} align={'center'}>
                <Text width={'100px'} border={'1px solid rgba(207, 208, 216, 0.60)'} borderRadius={'2px'} px={3} py={2}>
                  CSV Import
                </Text>
                <Flex gap={2}>
                  <Tooltip content="Copy">
                    <MdOutlineContentCopy
                      cursor={'pointer'}
                      size={'24px'}
                      color={'#262B47'}
                      onClick={handleCopyContent}
                    />
                  </Tooltip>
                  <Tooltip content="Download">
                    <MdOutlineFileDownload
                      cursor={'pointer'}
                      size={'24px'}
                      color={'#262B47'}
                      onClick={handleDownload}
                    />
                  </Tooltip>
                </Flex>
              </Flex>
              <AlertBanner
                width={'423px'}
                message={"Hover over each row's header to learn more about a field."}
                padding={'6px 16px 6px 16px'}
                my={2}
              />
              <TableContainer>
                <Table.ScrollArea>
                  <Table.Root size={'sm'}>
                    <Table.Header>
                      <Table.Row>
                        {data.length > 0 &&
                          Object.keys(data[0]).map(header => (
                            <Table.ColumnHeader
                              height={'40px'}
                              key={header}
                              style={{ border: 'none' }}
                              textAlign={numericColumns.includes(header) ? 'end' : undefined}
                            >
                              <HStack gap={1}>
                                <Tooltip content={tooltips[header as keyof typeof tooltips]}>
                                  <Text fontSize={'12px'}>{header.toUpperCase()}</Text>
                                </Tooltip>
                                {mandatoryColumns.includes(header) && asterikIcon}
                              </HStack>
                            </Table.ColumnHeader>
                          ))}
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data?.map((row, idx) => (
                        <Table.Row key={`${idx}_${row.transaction_external_id}`}>
                          {Object.keys(row).map((key, iidx) => (
                            <Table.Cell
                              key={`${iidx}_${row[key as keyof typeof row]}`}
                              height={'55px'}
                              style={{ border: 'none' }}
                              textAlign={numericColumns.includes(key) ? 'end' : undefined}
                            >
                              {row[key as keyof typeof row]}
                            </Table.Cell>
                          ))}
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table.Root>
                </Table.ScrollArea>
              </TableContainer>
            </>
          </DialogBody>
        </DialogContent>
      </DialogRoot>
      {showFileUpload && <SupportModal isOpen={showFileUpload} onClose={() => setShowFileUpload(false)} />}
    </>
  );
};

export default ImportExamples;
