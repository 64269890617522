import { Table } from '@chakra-ui/react';
import React from 'react';
import { PreviewRowItem } from 'types/shared-types';
import { getHumanReadableString } from 'utils/enum-helpers';

const getDisplayValue = (value: string | number | boolean) => {
  return getHumanReadableString(value.toString());
};

type PreviewRowProps = {
  idx: number;
  row: PreviewRowItem;
};

const PreviewRow = ({ row, idx }: PreviewRowProps) => {
  return (
    <Table.Row key={`${idx}_${row.transaction_external_id}`}>
      {Object.values(row).map((value, iidx) => (
        <Table.Cell border={'1px solid var(--gray-50, #EFEFF3)'} borderLeft={'none'} key={`${iidx}_${value}`}>
          {typeof value === 'boolean' ? getDisplayValue(value) : value}
        </Table.Cell>
      ))}
    </Table.Row>
  );
};

export default React.memo(PreviewRow);
